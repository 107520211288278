import axios, { AxiosError } from "axios";
import { uniqueId } from "./authProvider";
import { toast } from "@/components/ui/use-toast";
import { UserProvider } from "./userProvider";
import useAppStore from "@/store/store";
import { TokenProvider } from "./tokenProvider";
import { version, name } from "../../package.json";

//const BASE_URL = "https://api.ovideo.app/emsbt/";
const BASE_URL = "https://api.ovideo.app/emsbt/";

//const notify = () => toast("Here is your toast.");

export interface ErrorResponse {
  detail: string;
  status: number;
  title: string;
  traceId: string;
}

// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    // set base url

    // check if url has base url
    if (!config.baseURL) {
      config.baseURL = BASE_URL;
    }

    if (config.url?.includes("version")) return config;

    if (config.url?.includes("login")) return config;
    // check token and get token from locastorage
    let token = localStorage.getItem("token");

    if (token) {
      // check if url has query params
      if (config.url?.includes("?")) {
        // config.url = config.url + `&token=${token}`;
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        // config.url = config.url + `?token=${token}`;
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    // set device id to header
    config.headers["device-id"] = uniqueId();

    // Set various navigator properties in headers
    config.headers["appCodeName"] = navigator.appCodeName || "";
    config.headers["appName"] = name || "";
    config.headers["appVersion"] = version || "";
    config.headers["language"] = navigator.language || "";
    config.headers["languages"] = navigator.languages
      ? navigator.languages.join(", ")
      : "";
    config.headers["platform"] = navigator.platform || "";
    config.headers["product"] = navigator.product || "";
    config.headers["productSub"] = navigator.productSub || "";
    config.headers["userAgent"] = navigator.userAgent || "";
    config.headers["vendor"] = navigator.vendor || "";
    config.headers["vendorSub"] = navigator.vendorSub || "";
    config.headers["hardwareConcurrency"] = navigator.hardwareConcurrency
      ? navigator.hardwareConcurrency.toString()
      : "";
    config.headers["maxTouchPoints"] = navigator.maxTouchPoints
      ? navigator.maxTouchPoints.toString()
      : "";

    // append token to url as query param ?token=token or &token=token

    // set token to header
    return config;
  },
  function (error: AxiosError) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //  console.log(UserProvider.refreshToken());
    return response;
  },
  async function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (!error) return;

    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      error.config?.method?.toLowerCase() === "get" &&
      !error.config?.url?.includes("token")
    ) {
      if (TokenProvider.getRefreshToken()) {
        try {
          await UserProvider.refreshToken();
          await useAppStore.getState().getRoles();
        } catch (e) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
        }
      }
      // toast.error("An error occurred");
    }

    if (error.response?.data) {
      toast({
        title: "Error",
        description: error.response?.data
          ? (error.response?.data as { message?: string })["message"]
          : "An error occurred",

        duration: 5000,
      });
    }

    if (!error.response?.status) return Promise.reject(null);

    if (error.config?.url === "Login/checkProjectAccess")
      return Promise.reject(error);

    if (error.response?.status === 401) {
      if (error.config?.method === "post") {
        return;
      }

      // window.location.href = "/login";
      return Promise.reject(error);
    }
    const errorResponse = error.response
      ? (error.response!.data as ErrorResponse)
      : null;

    if (error.response?.status === 406) {
      if (errorResponse && errorResponse.detail === "Invalid token!") {
        window.location.href = "/login";
        //toast.error(errorResponse.detail);
      }
    }

    if (errorResponse && errorResponse.detail) {
      // toast.error(errorResponse.detail);
    } else {
      // if (error.response?.status !== 404) toast.error("An error ocurred");
    }

    return Promise.reject(errorResponse);
  }
);

export default axios;
