import { uniqueId } from "./authProvider";
import request from "./request";
import { UserProvider } from "./userProvider";

export interface Channels {
  total: number;
  content: Channel[];
}

export interface Models {
  content: TranslateModel[];
  total: number;
}

export interface Channel {
  _id: number;
  active: boolean;
  audio: Audio[];
  crypt: Crypt;
  name: string;
  pcrPid: number;
  record: Record;
  scrambled: boolean;
  serviceId: number;
  transcode: Transcode;
  video: Video;
  status: Status;
  failed_status?: string;
  unique_name: string;
  sourceType: "mpts" | "youtube";
  sourceId: number;
  nodeIdRec: number;
  nodeIdTrc: number;
  baseNodeTrc: number;
  baseNodeRec: number;
  transcribe: boolean;
  ready: boolean;
  queue_wait: number;
  progress: number;
  translations: string[];
}

export interface Audio {
  id: number;
  lang: string;
  sample_rate: number;
  type: string;
}

export interface Crypt {
  iv: string;
  key: string;
  type: string;
}

export interface Record {
  active: boolean;
  audio: any[]; // Replace with the appropriate type
  duration: number;
}

export interface Transcode {
  active: boolean;
  bitrate: number;
  duration: number;
  resolution: string;
  profiles: Profile[];
}

export interface Profile {
  name: string;
  acodec: string;
  bitrate: number;
  fps: number;
  width: number;
  height: number;
  vcodec: string;
  audiorate: number;
  preset: string;
  url: string;
  transcode: boolean;
  active: boolean;
}

export interface Video {
  frame_rate: number;
  height: number;
  id: number;
  type: string;
  width: number;
}

export interface Source {
  type?: "mpts" | "youtube";
  url: string;
  name: string;
  _id?: number;
  active?: boolean;
}

export interface TranslateModel {
  cuda: number;
  key: string;
  load: boolean;
  model_name: string;
  port: number;
}

export interface TrnascriptModel {
  _id: number;
  load: boolean;
  model_name: string;
}

export interface Language {
  code: string;
  language: string;
}

export interface Source {
  total: number;
  content: SourceContent[];
}

export interface SourceContent {
  _id: number;
  url: string;
  name: string;
  language: string;
  analyzed: boolean;
  is_public: boolean;
  translations: string[];
  scrambled: boolean;
  transcribe: boolean;
  project: string;
  active: boolean;
  is_protected: boolean;
  type: "youtube";
  unique_name: string;
  owner: string;
  crypt: Crypt;
  pcrPid: number;
  audio: Audio[];
  transcode: Transcode;
  failed_reason: string;
  uptime: number;
  time: number;
  status: Status;
  queue_wait: number;
  progress: number;
  ready: boolean;
  failed_status: string;
}

export interface Crypt {
  type: string;
  key: string;
  iv: string;
}

export interface Audio {
  id: number;
  type: string;
  lang: string;
  rate: number;
  channels: number;
}

export interface Transcode {
  active: boolean;
  duration: number;
  profiles: Profile[];
}

export interface Profile {
  name: string;
  acodec: string;
  bitrate: number;
  fps: number;
  width: number;
  height: number;
  vcodec: string;
  audiorate: number;
  preset: string;
  url: string;
  transcode: boolean;
  active: boolean;
}

export enum Status {
  Stopped = 0,
  Queued = 1,
  Initializing = 2,
  Recording = 3,
  Failed = 4,
}

export interface Session {
  total: number;
  content: SessionContent[];
}

export interface SessionContent {
  _id: string;
  payload: SessionPayload;
}

export interface SessionPayload {
  user_id: string;
  exp: number;
  is_secure: boolean;
  source_id: number;
  device_id: string;
  forwarded_for: string;
  secret_key: string;
}

export class ChannelProvider {
  static async getChannels() {
    const response = await request.get<Source>("/config/sources");

    return response.data;
  }

  static async getSources() {
    const response = await request.get<Source>("/config/sources");

    return response.data;
  }

  static async createChannel(data: Partial<SourceContent>) {
    const response = await request.post<SourceContent>("/config/sources", data);

    return response.data;
  }

  static async updateSource(data: Partial<SourceContent>) {
    const response = await request.put<SourceContent>("/config/sources", data);

    return response.data;
  }

  static async updateChannel(data: Partial<SourceContent>) {
    const response = await request.put<SourceContent>("/config/sources", data);

    return response.data;
  }

  static async deleteChannel(sourceId: number) {
    const response = await request.delete<SourceContent>(
      `/config/sources?_id=${sourceId}`
    );

    return response.data;
  }

  static async getLanguages() {
    const response = await request.get<{
      content: Language[];
    }>("/config/languages");

    return response.data;
  }

  static async getTranslateModels() {
    const response = await request.get<Models>("/config/translations");

    return response.data.content;
  }

  static async getTranscriptModels() {
    const response = await request.get<{ content: TrnascriptModel[] }>(
      "/config/transcriptions"
    );

    return response.data.content;
  }

  static async updateTranslateModels(model: TranslateModel) {
    const response = await request.put<Models>("/config/translations", model);

    return response.data.content;
  }

  static async openSession(
    channelId: number,
    isSecure: boolean = true
  ): Promise<string> {
    // request to /api/hls/create_session to open session

    const token = localStorage.getItem("token");

    const queryObj = {
      source: channelId.toString() ?? "",
      is_secure: isSecure.toString(),
      device_id: uniqueId(),
    };

    const url =
      "https://api.ovideo.app/hls/create_session" +
      "?" +
      new URLSearchParams(queryObj).toString();

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const { link } = await response.json();
      return link as string;
    } catch (err) {
      // refresh token again call openSession
      try {
        await UserProvider.refreshToken();
        return await this.openSession(channelId, isSecure);
      } catch (error) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        return await this.openSession(channelId, isSecure);
      }
    }
  }

  static async getSessions() {
    const url = "https://api.ovideo.app/hls/sessions";
    const token = localStorage.getItem("token");

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = (await response.json()) as Session;

    return data.content;
  }

  // close_session
  static async closeSession(sessionId: string) {
    const url = `https://api.ovideo.app/hls/close_session?session=${sessionId}`;
    const token = localStorage.getItem("token");

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  // /hls/live.m3u8
  static getLiveStream(_id: string, isSecure: boolean = true) {
    const url = `https://api.ovideo.app/hls/live.m3u8?session=${_id}&time=0`;

    const token = localStorage.getItem("token");
    const deviceId = uniqueId();

    return isSecure ? `${url}&token=${token}&device_id=${deviceId}` : url;
  }
}
